<script setup>
import SingleImage from '@/Components/Popup/SingleImage.vue';
import { ref } from 'vue';

const props = defineProps({
    media: {
        type: Object,
        required: true,
    },
    classes: {
        type: String,
        default: '',
    },
    loading: {
        type: String,
        default: 'lazy',
    },
});

const show = ref(false);
</script>
<template>
    <img
        ref="target"
        :loading="loading"
        :class="classes"
        :src="media.src"
        :alt="media.alt_text"
        :srcset="media.src_set"
        @click="show = true"
    >
    <SingleImage
        v-model:open="show"
        :image="media.src"
    />
</template>
